.hero{
    display: flex;
    justify-content: space-between;
}

.left-h{

    padding: 2rem;
    padding-top: 1.5rem;
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    
}

.right-h{
    flex: 1;
    position: relative;
    background-color: var(--orange);
}

.the-best-ad{
    width: fit-content;
    margin-top: 4rem;
    background-color: #363d42;
    color: white;
    border-radius: 4rem;
    padding: 20px 13px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    
}
.the-best-ad>span{
    z-index: 2;
}

.the-best-ad>div{

    width: 5.4rem;
    height: 80%;
    position: absolute;
    border-radius: 3rem;
    left: 8px;
    background-color: var(--orange);
    z-index: 1;
}
.hero-text{

    display: flex;
    gap: 1.5rem;
    flex-direction: column;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}

.hero-text>div:nth-of-type(3){

    text-transform: none;
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 1px;
    width: 80%;

}

.figures{

    display: flex;
    gap: 2rem;
}

.figures>div{

    display: flex;
    flex-direction: column;

}

.figures>div>span:nth-of-type(1){

    color: white;
    font-size: 2rem;
}

.figures>div>span:nth-of-type(2){

    color: var(--gray);
    text-transform: uppercase;
}

.hero-button{
    display: flex;
    gap: 1rem;
    font-weight: normal;

}

.hero-button>:nth-child(1){

    width: 8rem;
    color: white;
    background-color: var(--orange);
}

.hero-button>:nth-child(2){

    width: 8rem;
    color: white;
    background-color: transparent;
    border: 2px solid var(--orange);
}

.right-h> .btn{

    position: absolute;
    right: 3rem;
    top: 2rem;
    color: black;
}

.heart-rate{

    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding: 1rem;
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    right: 4rem;
    top: 7rem;
}

.heart-rate>img{

    width: 2rem;
}

.heart-rate>span:nth-child(2)
{
color: var(--gray);

}

.heart-rate>span:nth-child(3)
{
color: white;
font-size: 1.5rem;
}

.hero-img{
position: absolute;
    width: 23rem;
    top: 10rem;
    right: 8rem;
}

.hero-img-back{

    position: absolute;
    right: 22rem;
    top: 6rem;   
    width: 12rem;
}

.calories{

    display: flex;
    gap: 2rem;
    background-color: var(--caloryCard);
    border-radius: 5px;
    padding: 1rem;
    width: 13rem;
    position: absolute;
    top: 32rem;
    right: 29rem;
}

.calories>img{
    width: 3rem;
   
}

.calories> div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.calories> div> :nth-child(1){

    color: var(--gray);
}

.calories> div> :nth-child(2){

    color: white;
    font-size: 1.2rem;
}

.blur-h{
    width: 22rem;
    height: 30rem;
    left: 0;
    
}

@media screen and (max-width: 768px) 
{
    .hero{
        flex-direction: column;
    }

    .blur-h{
        width: 14rem;
    }
    .the-best-ad{
        margin-top: 0;
        font-size: small;
        align-self: center;
        transform: scale(0.8);
    }
    .hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }  
    
    .hero-text > div:nth-of-type(3){
        font-size: small;
        font-weight: 200;
        letter-spacing: 1px;
        text-align: center;
    }
    .hero-button{
        justify-content: center;
    }
    .figures > div > span:nth-of-type(1){

        font-size: large;
    }
    .figures > div> span:nth-of-type(2){
        font-size: small;
    }

    .right-h{
        position: relative;
        background: none;
    }
    .heart-rate{
        left: 1rem;
        top: 2rem;
    }
    .calories{
        position: relative;
        top: 5rem;
        left: 2rem;
    }
    .calories>img{
        width: 2rem;
    }
    .calories > div > :nth-child(2){
        color: white;
        font-size: 1rem;
    }
    .hero-img{
        position: relative;
        width: 15rem;
        left: 7rem;
        top: 4rem;
        align-self: center;
    }

    .hero-img-back{
        width: 15rem;
        left: 2rem;
        top: 0rem;
    }
}